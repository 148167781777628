
.alignCenter{
    align-items: center;

}
.column{
    flex-direction: column;

}
#idiotdiv{

}
.backedheader{
  /* background-color: rgb(182, 169, 146); */
  height:40px;
  padding-top:10px;
  margin:0px;
}
.c1{
    background-color: rgb(168, 164, 156);
}
.c2{
    background-color: rgb(214, 210, 198);
}
 
.c3{
    background-color: rgb(224, 222, 222);
}
.c4{
  background-color: #4682b9;
}
.c5{
  background-color: #cc5632;

}
.c6{
  background-color: #61bb59;

}
h1,h2{
    margin:5px;
    font-weight: 1;
    font-family: Arial, Helvetica, sans-serif;
}
#section80babies{
display: flex;
}
.header {
    position: fixed;
    top: 0;
  

    display: flex;
    width:80vw;
 
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9;
   
  }
  #logoImg{
    height:50px;
    width:200px;
    padding:5px;
  }
body{
  width:80vw;
  background-color: rgb(224, 222, 222);
  margin:0 auto;
}
#numberbutton{
  border:0px;
  
  background-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  transition: all ease-in-out .3s;
}
#numberbutton:hover{
  color:rgb(170, 122, 55);
}
  .header__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    padding-left: 20px;
  }
  
  .header__right {
    display: flex;
    align-items: center;
    padding-right:20px;
  }
  .header__middle{
    display: flex;
    align-items: center;
    
  }
  .header__middle>h3{
    font-weight: 400;
    padding-right:20px;
  }

  .headerButton{
    border:0px;
    background-color: rgba(0, 0, 0, 0);
    font-size: 20px;
    color:black;
    margin:0px;
    padding:3px;
    text-decoration: none;

  }
  a{
    text-decoration: none;


  }
  #mobile-view-phone-button{
    visibility: hidden;
    position: absolute;
  }
  .hidemeuntilmobile{
    visibility: hidden;
    position: absolute;
  }
  @media (max-width: 1032px) {
    .headerButton{
     font-size:16px;
    }
  
   }
   @media (max-width: 772px) {
    .headerButton{
        font-size: 22px;
    }
    .header__left{
      flex-direction: row;
      align-items: center;
    }
    .header__middle{
        visibility: hidden;
        position: absolute;
        top:0;
        left:0
    }
    .hidemeuntilmobile{
      visibility: visible;
      position: relative;
    }
    

   }




  .hoverable{
    transition: all .3s ease-in-out;
  }
  .hoverable:hover{
    color:rgb(170, 122, 55);
  }
  .borderRight{
    border-right: 2px solid black;
  }
  .borderLeft{
    border-left: 2px solid black;
  }
  .header__btn {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    background-color: white;
    color: black;
    font-size: 1.25rem;
    cursor: pointer;
  }
  

  
.image-slider-container{
    height: 70vh;
}


  .image-slider {
    width: 100%;
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.5s ease-in-out;

  }
  
  .image-slider-text {
    position: relative;
    top:-300px;
 
    text-align: center;
    font-size: 3vw;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 4px black;
  }

.image-grid {
    
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width:75%;
  margin: 0 auto;
}
.image-grid-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image-grid-item {
  width: calc(10% - 20px);
display: flex;
flex-direction: column;
align-items: center;
  overflow: hidden;
  margin-bottom: 30px;
  padding:3px;
}
  
  img {
    width: 100%;
  
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .image-grid-item {
      width: 30%;
    }
  }




  @media (max-width: 800px) {
    .FullWidthImageText {
      flex-direction: column;
    }
  }
  


  .wrapper{
    width:100%;
  }

 

  .spaceAround{
    justify-content: space-between;
  }

  .flex{
    display: flex;
  }
  

  .divider{
    height:150px;
    width:100%;
    background-color: rgb(99, 108, 116);
  }
  footer{
    
    width:100%;
    background-color: #333;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    height:100px;
   
  }
  footer>*{margin:0px;}
  .padBottom{
    margin-bottom: 50px;
  }
  .underline{
    border-bottom: 2px solid black;
  }
  .tightboi{
    width:fit-content;
    height:fit-content;
  }
  
  .servicesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:5px;
}

section{
  width: 32%;
  text-align: center;
  transition: all .2s ease-in-out;
}

@media (max-width: 768px) {
  section {
    width: 100%;
    margin-bottom: 20px;
  }
 
}

.imageContainer >img{
  width: 100%;
  background-color: lightgray;
  margin-bottom: 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}


@media (max-width: 1600px) {
    .image-slider-text{
 
       
    }
  }
  @media (max-width:850px) {
    .image-slider-text{
      font-size: 6vw;
      
    }
  
  }



  /* AboutSection.css */

.about-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  border-top:70px solid rgb(73, 73, 73);
  height:80vh
  }
  
  .about-section-image-container {
  
    width: 40%;
   
  }
  
  .about-section-image {
    width: 100%;
    height: auto;
  }
  
  .about-section-text-container {
    width: 60%;
    text-align: left;
    padding-left: 20px;
  }
  
  .about-section-header {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
  }
  
  .about-section-paragraph {
    font-size: 18px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
    padding:5px;
  }
  p{
    font-size: 20px;
  }
  @media (max-width: 1100px) {
    .about-section-container {
      flex-direction: column;
      height:fit-content;
    }
  
    .about-section-image-container {
      width: 60%;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .about-section-text-container {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }
  

  .contactForm{
        background-color: rgb(209, 205, 197);
        position: fixed;
        width:50%;
        
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1rem;
        border-radius: 5px;
        box-shadow: 0 0 10px #333;
        display: flex;
        flex-direction: column;
    
        font-size: 14px;
    
  }
  .contactForm>h3{
    margin:4px;
    font-size: 2.5vh;
    font-weight: 400;
    color:black;
  }
 .contactInputContainer{
  display:flex;
  width:100%;
  margin:5px;
  justify-content: space-between;
  
 }
  .contactInput{
    width:90%;
    height:20px;
    font-size:16px;
  }
  .contactTextArea{
    width:90%;
    height:200px;
    resize: none;
    font-size:16px;
  }

  .promptCloseButton{
    position: absolute;
    right:0;
    top:0px;
    font-size: 25px;
    color:rgb(17, 17, 17);
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  @media (max-width: 950px) {
    .contactForm{
      width:80vw;
      
     }
     .contactInput{
      width:75vw;

    }
    .contactTextArea{
      width:75vw;

    }
  }

  @media(max-height:650px){
    .contactTextArea{
      height:150px;

    }
  }




  
  .header1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .section1-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;

  }
 #mrspecial{
  font-size: 28px;
 }
  
  .section1-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .section1-list li {

    height: 30px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  @media only screen and (max-width: 600px) {
    .section1 {
      width: 90%;
    }
    .about-section-image-container{
      width:100%;
    }

    #section80babies{
      
    }
    #thekid{
      position: relative;
      top:-400px;
    }

  }
  
  @media (max-width: 1200px) {
    .section1-list li {

    height: fit-content;
  }
  body{
    width:96vw;
  }
  .header{
    width:96vw
  }
}


@media (max-width: 620px) {
  #logoImg{
    height:40px;
    width:170px;
    padding:5px;
  }

  .headerButton{
    font-size: 5vw;
    font-style: none;


  }
  .header{
    flex-direction: column;
  }
  .header__right{
    justify-content: space-between;
    border-top: 1px solid rgb(100, 99, 98);
  }
  
  .header__left{
    padding:0px;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
    width:100%;
  }
}





.container2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}

@media (max-width: 600px) {
  .container2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "item2 item2" 
      "item3 item1"
      ;
  }





  .item2:nth-of-type(2) {
    grid-area: item2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .item2:nth-of-type(1){
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: item3;
  }
  .item2:nth-of-type(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: item1;
  }
}
section:hover{
  padding:10px;
  /* cursor: pointer; */
}

#residentialPainting:hover{
  background-color: #4682b9;
  
}
#cabinetPainting:hover{
  background-color: #cc5632;

}
#commercialPainting:hover{
  background-color: #61bb59;
}

.subpage{
  position: relative;
  top:100px;
  min-height:90vh;
  margin-bottom: 150px;
}
.subpage-header{
  font-weight:350;
  font-size:2vw
}

.subpage-section>h3{
  font-weight: 400;
  font-size:1.3vw
}
.subpage-content{
  width:100%;
}
.subpage-text{
  
  padding:10px;
}
.subpage-text>p{
  font-size: 18px;

}
.subpage-images{
  min-height: 200px;
  background-color: #333;

}
.subpage-images>img{
  border:1px solid white;
  min-width: 20vw;
  min-height: 400px;
  
}
.full{
  width:100%;
}
.flip{
  flex-direction: row-reverse;
}
.flipvert{
  flex-direction: column-reverse;
}
.halfed{
  width:50%;
}
#thecream{
  width:50%;
}
.gridboi{
  display: grid;
  grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "item1 item3" 
      "item2 item3"
      ;
}
#gridboi1{
  grid-area: item1;
}
#gridboi2{
  grid-area: item2;
}
#gridboi3{
  grid-area: item3;
}